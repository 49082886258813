<template>
	<div class="platform">
		<Header/>
		<!-- 功能特点 -->
		<div class="region-com region-trait k_flex_column_jcc_aic">
			<div class="region-title k_flex_column_jcc_aic">
				<span>{{ $t('m.Allinonesolutiontool') }}</span>
				<!-- <a href="https://label.kymasf.com/" class="k_flex_jcc_aic" target="_blank">马斯夫标签编辑器</a> -->
				<div class="k_flex_column_aic">
					<img :src="platformUrl+'icon.png'"/>
					<span>{{ $t('m.MASFLabelEditor') }}</span>
				</div>
				<span>{{ $t('m.introduce') }}</span>
			</div>
			<img class="region-cont" :src="platformUrl+$t('m.trait_03')"/>
			<img class="region-desc" :src="platformUrl+$t('m.trait_04')"/>
		</div>
		
		<!-- 功能介绍 -->
		<div class="region-com region-introduce k_flex_column_jcc_aic">
			<div class="region-title">{{ $t('m.IntroductiontoFunction') }}</div>
			<div class="region-desc k_flex_wrap_jcsb">
				<div v-for="(item,index) in introduceList" :key="index" class="region-desc-single" :class="index%2>0?'odd k_flex_rowreverse_aic':'even k_flex_aic'">
					<img :src="platformUrl+item.img"/>
					<div class="k_flex_column_aifs region-desc-single-cont">
						<span class="single-title">{{item.title}}</span>
						<span class="single-cont">{{item.cont}}</span>
						<div v-if="item.desc.length>0&&item.desc" class="k_flex" v-for="(itemT,indexT) in item.desc" :key="indexT">
							<span>·  </span><span>{{itemT}}</span>
						</div>
					</div>
				</div>
			</div>
		</div>
		
		<!-- 功能展示 -->
		<div class="region-com region-display k_flex_column_jcc_aic">
			<div class="region-desc k_flex_wrap_jcsb">
				<img :src="platformUrl+item.img" v-for="(item,index) in displayList" :key="index"/>
			</div>
		</div>
		
		<!-- 页面入口 -->
		<div class="region-com region-nav k_flex_jcc_aic k_flex_column_jcc_aic">
			<div class="region-desc k_flex_jcc_aic">
				<span>{{ $t('m.WeProvideIntegrated') }}</span>
				<img :src="platformUrl+'logo.png'"/>
				<!-- <a href="https://label.kymasf.com/" class="k_flex_jcc_aic" target="_blank">免费使用</a> -->
			</div>
		</div>
	</div>
</template>

<script>
const Header = ()=>import('@c/header.vue')
export default{
	components:{
		Header
	},
	data() {
		return {
			// introduceList:[
			// 	{
			// 		title:'PDF导入-拆分',
			// 		cont:'无需手动切分PDF模板，对一页存在多个标签进行单任务拆分',
			// 		desc:[],
			// 		img:'introduce_01.png'
			// 	},
			// 	{
			// 		title:'喷墨、热敏设备控制',
			// 		cont:'支持添加喷墨打印机，对喷头等配置进行管理让您打印更加便捷无忧',
			// 		desc:[
			// 			'默认及修改常规设置',
			// 			'对打印机喷头进行调配',
			// 			'开发者模式调整'
			// 		],
			// 		img:'introduce_02.png'
			// 	},
			// 	{
			// 		title:'批量打印',
			// 		cont:'可按照客户或者订单分组实现批量打印',
			// 		desc:[
			// 			'支持分组加入打印队列',
			// 			'支持喷墨/热敏打印',
			// 			'支持批量打印',
			// 			'支持喷墨打印设备管理'
			// 		],
			// 		img:'introduce_03.png'
			// 	},
			// 	{
			// 		title:'账号管理',
			// 		cont:'可设置团队/部门账号，并对账号进行权限管理。',
			// 		desc:[
			// 			'对不同的账号进行权限分配',
			// 			'对子账号进行任务下发'
			// 		],
			// 		img:'introduce_04.png'
			// 	},
			// 	{
			// 		title:'模板中心',
			// 		cont:'海量模板素材',
			// 		desc:[
			// 			'素材种类多，涉及行业广',
			// 			'可收藏模板，也可把标签分享至模板中心',
			// 			'支持编辑模板',
			// 		],
			// 		img:'introduce_05.png'
			// 	}
			// ],
			// displayList:[
			// 	{
			// 		img:'display_01.png'
			// 	},
			// 	{
			// 		img:'display_02.png'
			// 	},
			// 	{
			// 		img:'display_03.png'
			// 	},
			// 	{
			// 		img:'display_04.png'
			// 	},
			// 	{
			// 		img:'display_05.png'
			// 	},
			// 	{
			// 		img:'display_06.png'
			// 	}
			// ]
		}
	},
	computed: {
		platformUrl() {
		  return 'https://oss-www.kymasf.com/img/platform/'
		},
		introduceList(){
		return	[
				{
					title:this.$t('m.PDFImportSplit'),
					cont:this.$t('m.Noneedtomanually'),
					desc:[],
					img:'introduce_01.png'
				},
				{
					title:this.$t('m.ControlofInkjetorthermalprinter'),
					cont:this.$t('m.Supportsinkjetprinters'),
					desc:[
					this.$t('m.DefaultandGeneral'),
					this.$t('m.Printheadconfiguration'),
					this.$t('m.Developermode')
					],
					img:'introduce_02.png'
				},
				{
					title:this.$t('m.BatchPrinting'),
					cont:this.$t('m.Batchprintingcanbeachieved'),
					desc:[
					this.$t('m.Supportsaddinggroupsintoprintqueues'),
					this.$t('m.Inkjetthermalprintingsupport'),
					this.$t('m.Supportbatchprinting'),
					this.$t('m.Inkjetprintingdevicemanagement')
					],
					img:'introduce_03.png'
				},
				{
					title:	this.$t('m.AccountManagement'),
					cont:	this.$t('m.Youcansetupteamdepartment'),
					desc:[
					this.$t('m.Assignprivilegestodifferentaccounts'),
					this.$t('m.Assigntaskstosubaccounts')
					],
					img:'introduce_04.png'
				},
				{
					title:this.$t('m.TemplateCenter'),
					cont:this.$t('m.Hugeamountoftemplates'),
					desc:[
					this.$t('m.Manykindsofmaterials'),
					this.$t('m.Collecttemplates'),
					this.$t('m.Supporttemplatesediting'),
					],
					img:'introduce_05.png'
				}
			]
	  },
		displayList(){
			return [
				{
					img:this.$t('m.display_01'),
				},
				{
					img:this.$t('m.display_02'),
				},
				{
					img:this.$t('m.display_03'),
				},
				{
					img:this.$t('m.display_04'),
				},
				{
					img:this.$t('m.display_05'),
				},
				{
					img:this.$t('m.display_06'),
				}
			]
		}
},
	mounted() {
		
	},
	methods: {
		
	}
}
</script>

<style lang="less" scoped>
.platform{
	
	// 功能特点
	.region-trait{
		background-color: #2D6CFF;
		min-width: 1200px;
		background: url('https://oss-www.kymasf.com/img/platform/trait_01.png') left top no-repeat, url('https://oss-www.kymasf.com/img/platform/trait_02.png') right top no-repeat,#2D6CFF;
		position: relative;
		.region-title{
			width: 830px;
			position: absolute;
			top: 52px;
			div{
				margin-bottom: 25px;
				margin-top: 15px;
				span{
					letter-spacing:2px;
					margin-top: 5px;
					font-size: 18px;
					font-family: Microsoft YaHei;
					font-weight: bold;
					color: #FFFFFF;
					opacity: 0.8;
				}
			}
			span{
				&:nth-child(1){
					font-size: 36px;
					font-family: Microsoft YaHei;
					font-weight: bold;
					color: #FFFFFF;
				}
				&:nth-child(3){
					font-size: 22px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #FFFFFF;
					line-height: 36px;
					text-align: center;
				}
			}
			a{
				width: 228px;
				height: 52px;
				background: #FFFFFF;
				border-radius: 8px;
				font-size: 24px;
				font-family: Microsoft YaHei;
				font-weight: bold;
				color: #2D6CFF;
				margin-top: 18px;
				margin-bottom: 22px;
			}
		}
		.region-cont{
			margin-top: 160px;
			margin-bottom: 40px;
		}
		.region-desc{
			margin-bottom: 42px;
		}
	}
	
	// 功能介绍
	.region-introduce{
		.region-title{
			width: 1200px;
			text-align: left;
			font-size: 40px;
			font-family: Microsoft YaHei;
			font-weight: bold;
			color: #000000;
			margin-top: 43px;
			margin-bottom: 33px;
		}
		.region-desc{
			width: 1200px;
			margin-bottom: 46px;
			.region-desc-single{
				&:nth-child(n+2){
					margin-top: 38px;
				}
				.region-desc-single-cont{
					height: 458px;
					width: 398px;
					box-sizing: border-box;
					.single-title{
						font-size: 32px;
						font-family: Microsoft YaHei;
						font-weight: bold;
						color: #2D6CFF;
					}
					.single-cont{
						font-size: 18px;
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: #000000;
						margin-top: 20px;
					}
					div{
						font-size: 18px;
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: #000000;
						margin-top: 20px;
						span{
							&:nth-child(1){
								margin-right: 5px;
							}
						}
					}
					// span{
					// 	&:nth-child(1){
					// 		font-size: 32px;
					// 		font-family: Microsoft YaHei;
					// 		font-weight: bold;
					// 		color: #2D6CFF;
					// 	}
					// 	&:nth-child(2){
					// 		font-size: 18px;
					// 		font-family: Microsoft YaHei;
					// 		font-weight: 400;
					// 		color: #000000;
					// 		margin-top: 20px;
					// 		// margin-bottom: 10px;
					// 	}
					// 	&:nth-child(n+3){
					// 		font-size: 18px;
					// 		font-family: Microsoft YaHei;
					// 		font-weight: 400;
					// 		color: #000000;
					// 		margin-top: 20px;
					// 		// line-height: 30px;
					// 	}
					// }
				}
			}
			.odd{
				.region-desc-single-cont{
					padding: 20px 30px 0 50px;
				}
			}
			.even{
				.region-desc-single-cont{
					padding: 20px 50px 0 30px;
				}
			}
		}
	}
	
	// 功能展示
	.region-display{
		background: #F0F1F2;
		.region-desc{
			width: 1200px;
			margin-bottom: 42px;
			margin-top: 42px;
			img{
				&:nth-child(n+4){
					margin-top: 25px;
				}
			}
		}
	}
	
	// 页面入口
	.region-nav{
		position: relative;
		height: 201px;
		background: #2D6CFF;
		.region-desc{
			width: 1200px;
			a{
				position: absolute;
				width: 162px;
				height: 52px;
				background: #FFFFFF;
				border-radius: 10px;
				font-size: 24px;
				font-family: Microsoft YaHei;
				font-weight: bold;
				color: #2D6CFF;
				margin-top: 60px;
			}
			span{
				position: absolute;
				font-size: 34px;
				font-family: Microsoft YaHei;
				font-weight: bold;
				// margin-bottom: 100px;
				color: #FFFFFF;
			}
			img{
				position: absolute;
				width: 174px;
				height: 174px;
				margin-bottom: 2px;
			}
		}
	}
}
</style>